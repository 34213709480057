//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "./tableclientes.css";
//IMAGENES/ICONOS
import { get_vehicle } from "../../services/assets/assets";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import Set_vehicle from "./set_vehicle";
import CREATE from "../../imgComponents/create.png";
import { CustomDialog } from "react-st-modal";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const VEHICLES_V2 = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [menssage, setmenssage] = useState("Consultando Vehículos...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");


  const update_identifiers =
    [
      { route: 'string' },
      { brand: 'string' },
      { model: 'String' },
      { description: 'string' },
      { vehicular_config: 'string' },
      { license_plate: 'string' },
      { year_model: 'string' },
      { asegura_resp_civil: 'string' },
      { poliza_resp_civil: 'string' },
      { asegura_med_ambiente: 'string' },
      { poliza_med_ambiente: 'string' },
      { asegura_carga: 'string' },
      { poliza_carga: 'string' },
      { prima_seguro: 'string' },
      { date_register: 'string' }
    ];

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handleSubmit = async (msjAler, query_filters_flag, query_filters_data) => {
    setmodalT(true);
    setData([]);
    setIsLoading(true);
    setTimeout(async () => {
      setmodalGeneral(false);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              if (query_filters_flag) {
                const d = await get_vehicle();
                setquery_filters(query_filters_data);
              } else {
                const d = await get_vehicle();
                const data = d === null ? [] : d.data;
                var ndt = update_null_identifiers(data, update_identifiers);
                setmodalT(false);
                setData(ndt);
                setIsLoading(false);
                if (msjAler?.length > 1) {
                  notificationSystemRef.current.addNotification({
                    message: (
                      <AlertResponse msj={msjAler} view={true}></AlertResponse>
                    ),
                    level: "success",
                    position: "br",
                    autoDismiss: 10,
                  });
                }
              }
            } catch (err) {
              var errroM = err?.response?.data?.errorMessage;
              setData([]);
              notificationSystemRef.current.addNotification({
                message: (
                  <AlertResponse
                    msj={
                      errroM === undefined
                        ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                        : errroM
                    }
                    view={false}
                  ></AlertResponse>
                ),
                level: "error",
                position: "br",
                autoDismiss: 60,
              });
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }, 2000);
  };



  const columns = useMemo(
    () => [
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        size:100
      },
      {
        id: "brand",
        header: "Marca",
        accessorKey: "brand",
        size:120
      },
      {
        id: 'model',
        header: "Modelo",
        accessorKey: "model",
        size:120

      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
        size:160
      },
      {
        id:"permissible_gross_vehicle_weight",
        header: "Peso Bruto Vehícular",
        accessorKey: "permissible_gross_vehicle_weight",
        size:120

      },
      {
        id: "vehicular_config",
        header: "Configuración Vehícular",
        accessorKey: "vehicular_config",
        size:160

      },
      {
        id: "license_plate",
        header: "Placa",
        accessorKey: "license_plate",
        size:120

      },
      {
        id: "year_model",
        header: "Año del Modelo",
        accessorKey: "year_model",
        size:160

      },
      {
        id: "asegura_resp_civil",
        header: "Aseguradora Responsabilidad Civil",
        accessorKey: "asegura_resp_civil",
      },
      {
        id: "poliza_resp_civil",
        header: "Póliza Responsabilidad Civil",
        accessorKey: "poliza_resp_civil",
      },
      {
        id: "asegura_med_ambiente",
        header: "Aseguradora Medio Ambiente",
        accessorKey: "asegura_med_ambiente",
      },
      {
        id: "poliza_med_ambiente",
        header: "Póliza Medio Ambiente",
        accessorKey: "poliza_med_ambiente",
      },
      {
        id: "asegura_carga",
        header: "Aseguradora de Carga",
        accessorKey: "asegura_carga",
      },
      {
        id: "poliza_carga",
        header: "Póliza de Carga",
        accessorKey: "poliza_carga",
      },
      {
        id: "prima_seguro",
        header: "Prima de Seguro",
        accessorKey: "prima_seguro",
      },
      {
        id: "date_register",
        header: "Fecha de registro",
        accessorKey: "date_register",
      },

      
      
    ],
    []
  );

  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  return (
    <div className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR VEHÍCULOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        onStateChange={handleStateChange}
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                  title="Registrar Vehículo"
                  src={CREATE}
                  style={{ "cursor": "pointer" }}
                  className=""
                  onClick={async () => {
                    const result = await CustomDialog(
                      <Set_vehicle
                        handleSubmit={handleSubmit}
                        setmodalT={setmodalT}
                        alertas={alertas}
                      />,
                      {
                        className: "custom-modal-3",
                        title: "Registrar Vehículo",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                  }}
                ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 75
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handleSubmit={handleSubmit}
            setreconsult={setreconsult}
            refresh_token={refresh_token}
            alertas={alertas}
          />
        ]}
        renderToolbarInternalActions={({ table, index }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table}
            file_name={"Consultar Vehículos"}
            update_identifiers={update_identifiers}
            settotal_rows={settotal_rows}
            query_filters={query_filters}
            query_filters_dt={query_filters_dt}
            handle_get_consult={handleSubmit}
            key={index}
            handle_success_filter={handle_success_filter}
            share_filter={true}
            total_rows={total_rows !== "" ? total_rows : 0}
          />
        )
        }
      />
    </div>
  );
};

export default VEHICLES_V2;
