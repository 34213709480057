import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ValidationTextField } from '../../../main_components/lable/labe'; // Asegúrate de que el componente está correctamente importado
import Grid from '@mui/material/Grid';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';



function FORM_INVOICES_HEADERS({ open, onClose, data, onSave, isNew }) {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});

  console.log(formData?.date)
  useEffect(() => {
    setFormData(data);
  }, [data, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;


    setFormData(prev => ({ ...prev, [name]: processedValue }));

    // Limpiar errores cuando se cambia un campo
    setErrors(prev => ({ ...prev, [name]: '' }));

  };

  const handleSubmit = () => {
    const validationErrors = {};

    // Aquí debes definir las validaciones específicas para cada nuevo campo
    if (!formData.supplierCode) {
      validationErrors.supplierCode = 'El código del proveedor es obligatorio';
    }

    if (!formData.date) {
      validationErrors.date = 'La fecha es obligatoria';
    }

    if (!formData.docType) {
      validationErrors.docType = 'El tipo de documento es obligatorio';
    }

    if (!formData.docID) {
      validationErrors.docID = 'El folio del documento es obligatorio';
    }

    if (!formData.currency) {
      validationErrors.currency = 'La moneda es obligatoria';
    }

    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, isNew,data.docID,data.supplierCode);
      onClose();
    } else {
      setErrors(validationErrors);
    }
  };


  const handleDateChange = (newValue) => {
    setFormData(prev => ({ ...prev, date: newValue }));
    setErrors(prev => ({ ...prev, date: '' }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isNew ? 'Agregar Encabezado de Factura' : 'Editar Encabezado de Factura'}</DialogTitle>
      <DialogContent style={{ zIndex: 2000 }}>
        <Grid container spacing={2}>
          {/** Ejemplo de campo: Proveedor */}
          <Grid item xs={8} style={{ paddingTop: "22px" }}>
            <ValidationTextField
              label="Proveedor"
              required
              placeholder="Código de Proveedor"
              fullWidth
              variant="outlined"
              name="supplierCode"
              value={formData?.supplierCode || ''}
              error={!!errors?.supplierCode}
              helperText={errors?.supplierCode}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={4} style={{ paddingTop: "22px" }}>
            <ValidationTextField
              label="Tipo de Documento"
              required
              placeholder="Documento"
              fullWidth
              variant="outlined"
              name="docType"
              value={formData?.docType || ''}
              error={!!errors?.docType}
              helperText={errors?.docType}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/** Ejemplo de otros campos */}
          <Grid item xs={4} >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha"
                value={formData?.date}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} fullWidth error={!!errors.date} helperText={errors.date} />}
              />
            </LocalizationProvider>
          </Grid>



          <Grid item xs={5}>
            <ValidationTextField
              label="Folio"
              required
              placeholder="Folio"
              fullWidth
              variant="outlined"
              name="docID"
              value={formData?.docID || ''}
              onChange={handleChange}
              error={!!errors?.docID}
              helperText={errors?.docID}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={3} >
            <ValidationTextField
              label="Moneda"
              required
              placeholder="Moneda"
              fullWidth
              variant="outlined"
              name="currency"
              value={formData?.currency || ''}
              error={!!errors?.currency}
              helperText={errors?.currency}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error" sx={{ m: 1, width: "100%" }}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="success" sx={{ m: 1, width: "100%" }}>
          {isNew ? 'Crear' : 'Actualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FORM_INVOICES_HEADERS;
